@import '../../styles/settings';

.Button {
  display: inline-flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid $primary;
  background-color: $primary;
  color: #fff;
  margin:2px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 1px;
  &--block {
    width: 100%;
  }
  i {
    font-size: 14px;
    html[dir='ltr'] & {
      margin-right: 8px;
    }
    html[dir='rtl'] & {
      margin-left: 8px;
    }
  }
  &:hover,
  &:focus {
    color: #fff;
    background: darken(desaturate($primary, 0.43), 7.45);
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &--xs {
    font-size: 12px;
    padding: 10px rem(22);
    font-weight: 400;
    text-transform: capitalize;
  }
  &--capitalize {
    text-transform: capitalize;
  }

  &--sm {
    font-size: rem(14);
    padding: 14px rem(24);
  }

  &--md {
    font-size: rem(14);
    padding: rem(17) rem(20);
  }

  &--lg {
    font-size: 1.375rem;
    padding: 1.125rem 4.5rem;
  }

  &__light,
  &--light {
    color: $primary;
    border: 1px solid rgba($primary, 0.15);
    background-color: rgba($primary, 0.1);
    &:hover,
    &:focus {
      color: $primary;
      background: rgba($primary, 0.15);
      outline: none;
    }
  }
  &__default {
    color: $gray-700;
    border: 1px solid $border-color;
    background-color: #f8f8f8;

    &:hover,
    &:focus {
      color: $gray-700;
      background: $border-color;
      outline: none;
    }
  }

  &__link {
    color: $primary;
    border: none;
    background-color: transparent;
    padding: 0;
    font-weight: 400;
    font-family: $font-family-sans-serif;

    &:hover,
    &:focus {
      color: $primary;
      border: none;
      background-color: transparent;
      outline: none;
    }
  }
  &__border-none {
    border: 0 !important;
  }
  &__outline-primary {
    color: $primary;
    border-color: $primary;
    background-color: #fff;
    &:hover,
    &:focus {
      color: #fff;
      i {
        color: #fff;
        &:after,
        &:before {
          background: #fff;
        }
      }
    }
  }
}

.ButtonBlock {
  &--gap-sm {
    padding: 1rem 0;
  }
  &--gap-lg {
    padding: 2rem 0;
  }
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

.PaymentType__btn ,.PaymentType__btn:hover  {
  background-color: white;
  color: #028283;
  border: 1px solid #098283;
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  border-radius: 0px;
  padding: 15px;
}
.btnInfoLabel{
  font-size: 16px;
}
.no-padding {
  padding:0px!important;
}
